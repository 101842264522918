import React from 'react'
import { createParty } from '../partySlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Action, Dispatch } from 'redux'
import { RootState } from '../../../app/store'
import { PartyState } from '../partySlice'
import { Location } from '../../location/Location'
import { Window } from '../../window/Window'

interface Props {
    party: PartyState
    location: Location
    window: Window
    createParty: () => void
}

const CreateButton = ({ location, party, createParty, window }: Props) => {
    if (party.created) {
        return <Redirect push to={`/party/${party.party!.id}/${location.id}/${window.id}`} />
    }
    return (
        <div className="h-screen w-screen flex">
            <h1 className="text-center absolute">Browser only. Verrry Beta. Contact <a href="mailto:mal@mal.co.nz" className="underline">mal@mal.co.nz</a></h1>
            <div className="m-auto w-screen text-center">
                <h1 className="text-4xl mb-6">Video Parties Made For Lockdown</h1>
                <button
                    aria-label="Join Party"
                    onClick={() => createParty()}
                    className="bg-yellow-500 hover:bg-transparent hover:text-yellow-500 text-3xl p-3 pl-8 pr-8 rounded-full focus:outline-none"
                >
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> {' '}
        Create Party
        </button>
            </div>
        </div >
    )
}

const mapStateToProps = ({ location, party, window }: RootState) => ({ location, party, window })
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    createParty: () => dispatch(createParty()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateButton)
