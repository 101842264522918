import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Location} from './Location'


const initialState: Location = {
  id: '',
};

export const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setLocation: (state, action: PayloadAction<Location>) => {
      state.id = action.payload.id
    }
  },
});

export const {setId, setLocation} = slice.actions;

export default slice.reducer;
