import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Party} from './Party';

export interface PartyState {
  loaded: boolean
  loading: boolean
  creating: boolean
  created: boolean
  party: Party
}

const initialState: PartyState = {
  loaded: false,
  loading: false,
  created: false,
  creating: false,
  party: {id: '', key: ''},
};

export const slice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.created = false
      state.creating = true
      state.party.id = action.payload
    },
    createParty: (state, action: PayloadAction) => {
      state.created = false
      state.creating = true
    },
    createdParty: (state, action: PayloadAction<Party>) => {
      state.party = action.payload
      state.created = true
      state.creating = false
    },
  },
});

export const {createParty, createdParty, setId} = slice.actions;

// The function below is called a thunk and allows us to perform async logic.
// It can be dispatched like a regular action: `dispatch(incrementAsync(10))`.
// This will call the thunk with the `dispatch` function as the first
// argument. Async code can then be executed and other actions can be
// dispatched
// export const incrementAsync = (amount: number): AppThunk =>
// dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value
// from the state. Selectors can also be defined inline where they're used
// instead of in the slice file. For example: `useSelector((state: RootState)
// => state.counter.value)` export const selectCount = (state: RootState) =>
// state.counter.value;

export default slice.reducer;
