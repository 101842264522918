import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Window} from './Window'


const initialState: Window = {
  id: '',
  // deviceId: nanoid(),
  resolution: {
    x: window.screen.width,
    y: window.screen.height,
  },
};

export const slice = createSlice({
  name: 'window',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    // addDevice: (state, action: PayloadAction<Device>) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the immer
    //   library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.devices.push(action.payload)
    // },
    // removeDevice: (state, action: PayloadAction<string>) => {
    //   state.devices =
    //       state.devices.filter(device => device.id !== action.payload)
    // },
  },
});

export const {setId} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) =>
// state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default slice.reducer;
