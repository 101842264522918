import React from 'react'
import MicToggle from './mic/MicToggle'
import FullscreenToggle from './fullscreen/FullScreenToggle'
import ShareButton from './share/ShareButton'
const SettingsBar = () => <div className="absolute bottom-0 right-0 p-4 text-center w-screen">
    <FullscreenToggle />
    <MicToggle />
    <ShareButton />
</div>
export default SettingsBar
