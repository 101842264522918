import React, { Component } from 'react';


export type Props = {
    className: string,
    style: { [key: string]: any },
    id: string,
    videoTrack: any
};

interface VideoObject {
    volume: number
    onplaying: any
}

/**
 * Component that renders a video element for a passed in video track.
 *
 * @extends Component
 */
class Video extends Component<Props> {
    _videoElement: VideoObject | null;

    /**
     * Default values for {@code Video} component's properties.
     *
     * @static
     */
    static defaultProps = {
        className: '',

        id: ''
    };

    /**
     * Initializes a new {@code Video} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props: Props) {
        super(props);

        /**
         * The internal reference to the DOM/HTML element intended for
         * displaying a video.
         *
         * @private
         * @type {HTMLVideoElement}
         */
        this._videoElement = null;


        // Bind event handlers so they are only bound once for every instance.
        this._setVideoElement = this._setVideoElement.bind(this);
    }

    /**
     * Invokes the library for rendering the video on initial display. Sets the
     * volume level to zero to ensure no sound plays.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentDidMount() {
        if (this._videoElement) {
            this._videoElement.volume = 0;
        }

        this._attachTrack(this.props.videoTrack);
    }

    /**
     * Remove any existing associations between the current video track and the
     * component's video element.
     *
     * @inheritdoc
     * @returns {void}
     */
    componentWillUnmount() {
        this._detachTrack(this.props.videoTrack);
    }

    /**
     * Updates the video display only if a new track is added. This component's
     * updating is blackboxed from React to prevent re-rendering of video
     * element, as the lib uses {@code track.attach(videoElement)} instead.
     *
     * @inheritdoc
     * @returns {boolean} - False is always returned to blackbox this component
     * from React.
     */
    shouldComponentUpdate(nextProps: Props) {
        const currentJitsiTrack = this.props.videoTrack
            && this.props.videoTrack.jitsiTrack;
        const nextJitsiTrack = nextProps.videoTrack
            && nextProps.videoTrack.jitsiTrack;

        if (currentJitsiTrack !== nextJitsiTrack) {
            this._detachTrack(this.props.videoTrack);
            this._attachTrack(nextProps.videoTrack);
        }

        return false;
    }

    render() {
        return (
            <video
                autoPlay={true}
                style={this.props.style}
                className={this.props.className}
                id={this.props.id}
                ref={this._setVideoElement} />
        );
    }

    _attachTrack(videoTrack: any) {
        if (!videoTrack || !videoTrack.jitsiTrack) {
            return;
        }

        videoTrack.jitsiTrack.attach(this._videoElement);
    }

    _detachTrack(videoTrack: any) {
        if (this._videoElement && videoTrack && videoTrack.jitsiTrack) {
            videoTrack.jitsiTrack.detach(this._videoElement);
        }
    }

    _setVideoElement(element: any) {
        this._videoElement = element;
    }
}

export default Video;
