import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, takeEvery} from 'redux-saga/effects'

import {setParticipantGroupId} from '../conference/conferenceSlice';
import {getConference} from '../jitsi/conference';

import {emitDataChannelMessage, MessagePayload, receivedDataChannelMessage} from './dataChannelSlice';
import {Keys, ParticipantIsInGroup} from './messages'

function*
    participantIsInGroupSaga({groupId, participantId}: ParticipantIsInGroup) {
  yield put(setParticipantGroupId({
    key: groupId,
    participantId,
  }))
}

function*
    receivedDataChannelMessageSaga(
        {payload: {key, message}}: PayloadAction<MessagePayload>) {
  const {conference: {localParticipantId}} = yield select()
  switch (key) {
    case Keys.ParticipantIsInGroup:
      if (localParticipantId === '' ||
          message.participantId !== localParticipantId) {
        return;
      }
      yield call(participantIsInGroupSaga, message as ParticipantIsInGroup);
      break;
    default:
      console.error(key, message)
  }
}

function emitDataChannelMessageSaga(
    {payload: {key, message}}: PayloadAction<MessagePayload>) {
  const conference = getConference()
  const command = {
    value: key,
    attributes: message,
  };
  console.log('Sending Command', command)
  conference.sendCommandOnce(`clique`, command)
}

function* saga() {
  yield takeEvery(
      receivedDataChannelMessage.toString(), receivedDataChannelMessageSaga);
  yield takeEvery(
      emitDataChannelMessage.toString(), emitDataChannelMessageSaga);
}


export default saga;
