import React from 'react'
import { Participant, setParticipantGroupId } from '../../conference/conferenceSlice'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import VideoParticipant, { Sizes } from './VideoParticipant'
import { useDrop } from 'react-dnd'
import { Types } from '../Constants'
import { ParticipantTrack } from '../../conference/conferenceSlice'

interface VideoGroupProps {
    className?: string
    active: boolean
    groupId: string
    isNext: boolean
    participants: Participant[]
    setParticipantGroupId: (track: ParticipantTrack) => {}
}


const VideoGroup = ({ active, groupId, isNext, participants, setParticipantGroupId, className }: VideoGroupProps) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: Types.VIDEO,
        drop: (item: any) => {
            // don't do anything if we're already in the group
            if (item.participant.groupId === groupId) {
                return
            }
            setParticipantGroupId({
                participantId: item.participant.id,
                key: groupId,
            })
        },
        collect: mon => ({
            isOver: !!mon.isOver(),
            canDrop: !!mon.canDrop(),
        }),
    })
    if (isNext) {
        const classes = [
            "w-screen", "absolute", "bottom-0", "m-auto text-center pt-8 pb-8 text-3xl text-white",
        ]
        if (canDrop) {
            classes.push(isOver ? "bg-yellow-500" : "bg-gray-600")
        } else {
            classes.push('hidden')
        }
        return <div
            ref={drop}
            className={classes.join(" ")}
            style={{ zIndex: 90 }}
        >{canDrop && "Drop here to create new group."}</div>
    }
    const borderStyleClass = isOver ? "border-solid" : "border-solid"
    const backgroundColourClass = isOver ? "bg-yellow-500" : "bg-transparent"
    const borderColourClass = active ? 'border-yellow-500' : 'border-blue-300'
    // const classLookup: { [key: number]: number } = {
    //     1: 1,
    //     2: 2,
    //     3: 2,
    //     4: 2,
    //     5: 3,
    //     6: 3,
    //     7: 3,
    //     8: 3,
    //     9: 3,
    //     10: 4,
    //     11: 4,
    //     12: 4,
    //     13: 4,
    //     14: 4,
    //     15: 4,
    //     16: 4,
    // }
    const classes = [
        borderColourClass, borderStyleClass, "border-l-8", backgroundColourClass, "relative",
    ]
    classes.push("grid")
    classes.push(className || "")
    return (
        <div ref={drop} className={classes.join(" ")}>
            <div
                className="absolute top-0 right-0 bg-gray-800 text-xs p-2 pt-1 pb-1"
                style={{ zIndex: 90 }}
            >
                Group {groupId}
            </div>
            {Object.values(participants).map(participant => <VideoParticipant key={participant.id} participant={participant} className={active ? 'w-full' : "w-full"} size={active ? Sizes.M : Sizes.S} />)}
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    setParticipantGroupId: (track: ParticipantTrack) => dispatch(setParticipantGroupId(track)),
})
export default connect(
    null,
    mapDispatchToProps,
)(VideoGroup)
