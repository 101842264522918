import React from 'react'
import Clock from 'react-live-clock'
// @ts-ignore
const a = () => {
    // @ts-ignore
    return <div className="text-m p-2 text-right text-white bg-black w-screen h-screen">
        <div className="float-left">Mal Curtis</div>
        <Clock format={'HH:mm:ss'} ticking={true} timezone={'Pacific/Auckland'} />
    </div>

}

export default a
