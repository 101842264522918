import React from 'react';
import WindowRedirect from './components/redirect/WindowRedirect'
import LocationRedirect from './components/redirect/LocationRedirect'
import Conference from './modules/conference/components/Conference'
import CreateParty from './modules/party/components/Create'
import Notices from './modules/notices/components/Notices'
import Clock from './components/clock/Clock'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/party/:partyId" exact component={LocationRedirect} />
        <Route path="/party/:partyId/:locationId" exact component={WindowRedirect} />
        <Route path="/party/:partyId/:locationId/:windowId" exact component={Conference} />
        <Route path="/clock" exact>
          <Clock />
        </Route>
        <Route path="/" exact>
          <CreateParty />
        </Route>
      </Switch>
      <Notices />
    </Router >
  );
}
export default App;
