import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import id from '../../modules/id'

import {Notice, Type} from './Notice';

const initialState: Notice[] = [];

export const slice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    addNotice: (state, action: PayloadAction<Notice>) => {
      state.push(action.payload)
    },
    addSimpleNotice: {
      reducer: (state, action: PayloadAction<Notice>) => {
        state.push(action.payload)
      },
      prepare: (subType: string, message: string): {payload: Notice} => ({
        payload: {
          id: id(),
          type: Type.Simple,
          subType,
          content: {message, duration: 0},
        }
      }),
    },
    addSimpleNoticeWithDuration: {
      reducer: (state, action: PayloadAction<Notice>) => {
        state.push(action.payload)
      },
      prepare: (subType: string, duration: number, message: string): {
        payload: Notice
      } => ({
        payload:
            {id: id(), type: Type.Simple, subType, content: {message, duration}}
      }),
    },
    removeNotice: (state, action: PayloadAction<string>) => {
      return state.filter(notice => notice.id !== action.payload)
    },
  },
});

export const {
  addNotice,
  addSimpleNoticeWithDuration,
  addSimpleNotice,
  removeNotice
} = slice.actions;

export default slice.reducer;
