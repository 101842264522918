import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
const JitsiMeetJS: any = (window as any).JitsiMeetJS;

export interface ConferenceState {
  localParticipantId: string;
  localGroupId: string;
  participants: {[key: string]: Participant};
  groups: {[key: string]: string[]};
}

export interface Participant {
  id: string
  local: boolean, groupId: string
  audioTrackKey: string
  videoTrackKey: string
  connStatus: string
  properties: {[key: string]: string}
}

const initialState: ConferenceState = {
  localParticipantId: '',
  localGroupId: '',
  participants: {},
  groups: {},
};

export interface ParticipantTrack {
  key: string
  participantId: string
}
export interface Property {
  participantId: string
  name: string
  value: string
}

export interface PropertyChange {
  participantId: string;
  name: string;
  from: string;
  to: string;
}
export interface ConferenceEvent {
  err?: string
  event: string
  message: any
  args: any
}

const ensureParticipant = (state: ConferenceState, id: string) => {
  if (!state.participants[id]) {
    state.participants[id] = newParticipant(id)
  }
  state.participants[id].local = id === state.localParticipantId
};
const newParticipant = (id: string): Participant => ({
  id: id,
  audioTrackKey: '',
  groupId: '',
  videoTrackKey: '',
  properties: {},
  local: false,
  connStatus: '',
})

export const slice = createSlice({
  name: 'conference',
  initialState,
  reducers: {
    setParticipantGroupId:
        (state: ConferenceState,
         {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) => {
          if (state.localParticipantId === participantId) {
            state.localGroupId = key
          }
          ensureParticipant(state, participantId)
          // Remove the participant from the old group
          const currentGroupId = state.participants[participantId].groupId
          if (!state.groups[currentGroupId]) {
            state.groups[currentGroupId] = []
          }
          state.groups[currentGroupId] =
              state.groups[currentGroupId].filter(id => id !== participantId)
          if (state.groups[currentGroupId].length === 0) {
            delete state.groups[currentGroupId]
          }
          state.participants[participantId].groupId = key
          if (!state.groups[key]) {
            state.groups[key] = []
          }
          if (state.groups[key].indexOf(participantId) === -1) {
            state.groups[key].push(participantId)
          }
        },
    setLocalParticipantId:
        (state: ConferenceState, {payload}: PayloadAction<string>) => {
          state.localParticipantId = payload
        },
    setParticipantAudioTrack:
        (state: ConferenceState,
         {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) => {
          ensureParticipant(state, participantId)
          state.participants[participantId].audioTrackKey = key
        },
    setParticipantVideoTrack:
        (state: ConferenceState,
         {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) => {
          ensureParticipant(state, participantId)
          state.participants[participantId].videoTrackKey = key
        },
    removeParticipantAudioTrack:
        (state: ConferenceState, {payload}: PayloadAction<string>) => {
          state.participants[payload].audioTrackKey = ''
        },
    removeParticipantVideoTrack:
        (state: ConferenceState, {payload}: PayloadAction<string>) => {
          state.participants[payload].videoTrackKey = ''
        },
    [JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED as string]:
        (state: ConferenceState,
         {payload: {message, args}}: PayloadAction<ConferenceEvent>) => {
          ensureParticipant(state, message)
          state.participants[message].connStatus = args[1]
        },
    setProperty: (state, payload: PayloadAction<Property>) => {},
    participantPropertyDidChange: (state, {
      payload: {participantId, name, to}
    }: PayloadAction<PropertyChange>) => {
      ensureParticipant(state, participantId)
      state.participants[participantId].properties[name] = to
    },
  },
});

export const actions = slice.actions;
export const {
  participantPropertyDidChange,
  removeParticipantAudioTrack,
  setGroupId,
  setParticipantGroupId,
  setParticipantAudioTrack,
  setProperty
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic.
// It can be dispatched like a regular action: `dispatch(incrementAsync(10))`.
// This will call the thunk with the `dispatch` function as the first
// argument. Async code can then be executed and other actions can be
// dispatched export const incrementAsync = (amount: number): AppThunk =>
// dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value
// from the state. Selectors can also be defined inline where they're used
// instead of in the slice file. For example: `useSelector((state: RootState)
// => state.counter.value)`

export default slice.reducer;
