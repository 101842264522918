import React from 'react'
import { connect as connectJitsi, JitsiState } from '../jitsiSlice'
import { RootState } from '../../../app/store'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

interface ConnectedProps {
    connectJitsi: () => void
    jitsi: JitsiState
}

const Join: React.FunctionComponent<ConnectedProps> = ({ connectJitsi }) => (
    <div className="h-screen w-screen flex">
        <button
            aria-label="Join Party"
            onClick={() => connectJitsi()}
            className="bg-yellow-500 hover:bg-transparent hover:text-yellow-500 m-auto text-3xl p-3 pl-8 pr-8 rounded-full focus:outline-none"
        >
            <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> {' '}
        Join Party
        </button>
    </div >
)

const WithJitsiConnection: React.FunctionComponent<ConnectedProps> = (props) => {
    const { children, jitsi: { connected, connecting, joined, joining } } = props
    if (connected && joined) {
        return <React.Fragment>{children}</React.Fragment>
    }
    if (!connecting && !joining) {
        return <Join {...props} />
    }
    return <div className="h-screen w-screen flex">
        <div className="w-screen m-auto text-center pt-3 pb-3 text-3xl text-white bg-yellow-500">{connected ? 'Joining Party…' : 'Connecting…'}</div>
    </div>
}

export default connect(
    ({ jitsi }: RootState, { children }: any) => ({ jitsi, children }),
    {
        connectJitsi: () => connectJitsi(),
    }
)(WithJitsiConnection)
