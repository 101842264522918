import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../app/store'
import { Notice } from '../Notice'

interface Props {
    notices: Notice[]
}

const Notices = ({ notices }: Props) => {
    return (<div className="absolute bottom-0 left-0">
        {notices.map(notice => {
            const classes = ["w-64 bg-green-500 text-white hover:bg-transparent m-auto text-lg p-2 pl-4 pr-4 mr-4 ml-4 mb-4 rounded-md"]
            return <div key={notice.id} className={classes.join(" ")}>{notice.content.message}</div>
        })}
    </div>
    )
}

export default connect(({ notices }: RootState): Props => ({ notices }))(Notices)
