import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AudioState {
  tracks: string[]
}


const initialState: AudioState = {
  tracks: [],
}

export const slice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    addTrack: (state, {payload}: PayloadAction<string>) => {
      if (state.tracks.indexOf(payload) !== -1) {
        return
      }
      state.tracks.push(payload)
    },
    removeTrack: (state, {payload}: PayloadAction<string>) => {
      state.tracks = state.tracks.filter(track => track === payload)
    },
  },
});

export const {addTrack, removeTrack} = slice.actions;
export default slice.reducer;
