import React, { Component } from 'react';


export type Props = {
    /**
     * The JitsiLocalTrack to display.
     */
    audioTrack: {
        jitsiTrack: any
    }
};

/**
 * Component that renders a video element for a passed in video track.
 *
 * @extends Component
 */
export default class Audio extends Component<Props> {
    _audioElement: any;

    constructor(props: Props) {
        super(props);
        this._audioElement = null;
        this._setAudioElement = this._setAudioElement.bind(this)
    }

    componentDidMount() {
        this._attachTrack(this.props.audioTrack);
    }


    componentWillUnmount() {
        this._detachTrack(this.props.audioTrack);
    }

    shouldComponentUpdate(nextProps: Props) {
        const currentJitsiTrack = this.props.audioTrack
            && this.props.audioTrack.jitsiTrack;
        const nextJitsiTrack = nextProps.audioTrack
            && nextProps.audioTrack.jitsiTrack;

        if (currentJitsiTrack !== nextJitsiTrack) {
            this._detachTrack(this.props.audioTrack);
            this._attachTrack(nextProps.audioTrack);
        }

        return false;
    }

    render() {
        return (
            <audio autoPlay={true} muted={false} ref={this._setAudioElement} />
        )
    }

    _attachTrack(audioTrack: any) {
        if (!audioTrack || !audioTrack.jitsiTrack) {
            return;
        }
        audioTrack.jitsiTrack.attach(this._audioElement);
    }

    /**
     * Removes the association to the component's video element from the passed
     * in redux representation of jitsi video track to stop the track from
     * rendering.
     *
     * @param {Object} videoTrack -  The redux representation of the
     * {@code JitsiLocalTrack}.
     * @private
     * @returns {void}
     */
    _detachTrack(videoTrack: any) {
        if (this._audioElement && videoTrack && videoTrack.jitsiTrack) {
            videoTrack.jitsiTrack.detach(this._audioElement);
        }
    }

    /**
     * Sets an instance variable for the component's video element so it can be
     * referenced later for attaching and detaching a JitsiLocalTrack.
     *
     * @param {Object} element - DOM element for the component's video display.
     * @private
     * @returns {void}
     */
    _setAudioElement(element: any) {
        this._audioElement = element;
    }
}

