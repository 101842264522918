import {all} from 'redux-saga/effects'

import audioSagas from '../modules/audio/sagas'
import conferenceSagas from '../modules/conference/sagas'
import dataChannelSagas from '../modules/dataChannel/sagas'
import jitsiSagas from '../modules/jitsi/sagas'
import noticesSagas from '../modules/notices/sagas'
import participantSagas from '../modules/participant/sagas'
import partySagas from '../modules/party/sagas'
import settingsSagas from '../modules/settings/sagas'

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    audioSagas(), conferenceSagas(), dataChannelSagas(), jitsiSagas(),
    noticesSagas(), participantSagas(), partySagas(), settingsSagas()
  ])
}
