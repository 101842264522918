export interface ParticipantIsInGroup {
  participantId: string
  groupId: string
}

export const Keys = {
  ParticipantIsInGroup: 'ParticipantIsInGroup',
}

export default ParticipantIsInGroup
