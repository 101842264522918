import id from '../id'
const _tracks: {[key: string]: Object} = {};

export const addTrack = (track: any):
    string => {
      const key = id()
      _tracks[key] = track
      return key
    }

// export const removeTrack = (track: any):
//     string => {
//       const key = track.getSSRC();
//       delete _tracks[key];
//       return key;
//     }

export const getTrack = (key: string): Object => {
  return _tracks[key]
}
