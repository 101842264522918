import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConferenceState, Participant} from '../conference/conferenceSlice';

// const JitsiMeetJS: any = (window as any).JitsiMeetJS;

export interface Property {
  participantId: string
  name: string
  value: string
}

export interface PropertyChange {
  participantId: string;
  name: string;
  from: string;
  to: string;
}

const initialState = null

export const slice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    setProperty: (state, payload: PayloadAction<Property>) => {},
    participantPropertyDidChange:
        (state, payload: PayloadAction<PropertyChange>) => {

        },
  },
});

// export const {setProperty} = slice.actions;
export default slice.reducer;

export const selectValidParticipants = (participants:
                                            {[key: string]: Participant}):
    {[key: string]: Participant} => {
      return Object.keys(participants).reduce((prev, key, idx, accum: any) => {
        const {videoTrackKey, connStatus} = participants[key];
        if (videoTrackKey !== '' && connStatus !== 'interrupted') {
          accum[key] = participants[key];
        }
        return accum;
      }, {});
    }

interface splitParticipantsResponse {
  localGroup: Participant[]
  otherGroups: {[key: string]: Participant[]}
}
export const splitParticipantsIntoGroups =
    ({localGroupId, participants, groups}: ConferenceState):
        splitParticipantsResponse => {
          const validParticipants = selectValidParticipants(participants)
          const localGroup: Participant[] = [];
          const otherGroups: {[key: string]: Participant[]} = {};
          for (const groupId in groups) {
            if (groupId !== localGroupId) {
              otherGroups[groupId] = []
            }
            for (const participantId of groups[groupId]) {
              const participant = participants[participantId];
              if (groupId === localGroupId) {
                if (validParticipants[participantId]) {
                  localGroup.push(participant)
                }
              } else {
                if (validParticipants[participantId]) {
                  otherGroups[groupId].push(participant)
                }
              }
            }
            if (groupId !== localGroupId) {
              if (otherGroups[groupId].length === 0) {
                delete otherGroups[groupId]
              }
            }
          }
          return {
            localGroup, otherGroups
          }
        }
