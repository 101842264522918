import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// const JitsiMeetJS: any = (window as any).JitsiMeetJS;

export interface SettingsState {
  micEnabled: boolean
  fullscreenEnabled: boolean
  shareDisplayed: boolean
}


const initialState = {
  micEnabled: true,
  fullscreenEnabled: false,
  shareDisplayed: false
}

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFullscreen: (state, {payload}: PayloadAction<boolean>) => {
      state.fullscreenEnabled = payload
    },
    toggleFullscreen: (state) => {
      state.fullscreenEnabled = !state.fullscreenEnabled
    },
    toggleMic: (state) => {
      state.micEnabled = !state.micEnabled
    },
    toggleShareDisplayed: (state) => {
      state.shareDisplayed = !state.shareDisplayed
    }
  },
});

export const {
  setFullscreen,
  toggleMic,
  toggleFullscreen,
  toggleShareDisplayed
} = slice.actions;

export default slice.reducer;
