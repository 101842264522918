import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { toggleMic } from '../../settingsSlice'
import { RootState } from '../../../../app/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'

interface MicToggleProps {
    micEnabled: boolean
    toggleMic: () => void
}
const MicToggle = ({ micEnabled, toggleMic }: MicToggleProps) => {
    const classes = [
        'w-10', 'h-10', 'rounded-full', 'outline-none', 'focus:outline-none', 'm-4',
        micEnabled ? "bg-orange-600" : "bg-white",
        micEnabled ? "text-white" : "text-orange-600",
    ]
    const title = micEnabled ? "Mic is enabled" : "Mic is disabled"
    return (
        <button className={classes.join(" ")} onClick={() => toggleMic()} title={title}><FontAwesomeIcon icon={faMicrophone} /></button>
    )
}

export default connect(
    ({ settings: { micEnabled } }: RootState) => ({ micEnabled }),
    (dispatch: Dispatch<Action>) => ({
        toggleMic: () => dispatch(toggleMic()),
    })
)(MicToggle)
