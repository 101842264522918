import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { Action, Dispatch } from 'redux'
import { toggleShareDisplayed } from '../../settingsSlice'

interface ButtonProps {
    toggle: () => void
}
const ShareButton = ({ toggle }: ButtonProps) => {
    const classes = [
        'w-10', 'h-10', 'm-4', 'rounded-full', 'outline-none', 'focus:outline-none',
        'bg-white',
        'text-gray-800',
        'hover:bg-gray-300',
    ]
    return (
        <React.Fragment>
            <button
                className={classes.join(' ')}
                onClick={() => toggle()}
                title='Share Party URL'
            >
                <FontAwesomeIcon icon={faShare} />
            </button>
        </React.Fragment>
    )
}

export default connect(
    null,
    (dispatch: Dispatch<Action>) => ({ toggle: () => dispatch(toggleShareDisplayed()) }),
)(ShareButton)
