import {END, eventChannel, EventChannel} from 'redux-saga'
import {call, delay, put, select, spawn, take, takeEvery, takeLatest,} from 'redux-saga/effects'

import {RootState} from '../../app/store'
import {actions as conferenceActions, ConferenceEvent, participantPropertyDidChange} from '../conference/conferenceSlice'
import {receivedDataChannelMessage} from '../dataChannel/dataChannelSlice'
import {addNotice, newSimpleNotice, newSimpleNoticeWithDuration} from '../notices';

import {getConference, setConference} from './conference'
import {getConnection, setConnection} from './connection'
import {connect, connected, connectFailed, join, joined, joinFailed} from './jitsiSlice';
import {addLocalTracks} from './localTracks'
import {addTrack} from './tracks'

const JitsiMeetJS: any = (window as any).JitsiMeetJS;

interface ConnectMessage {
  err?: Error
  connection?: any
}

const connectToJitsi = ():
    EventChannel<ConnectMessage> => {
      return eventChannel((emitter) => {
        JitsiMeetJS.init({});
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
        // const state = yield select()
        const connection = new JitsiMeetJS.JitsiConnection(null, null, {
          serviceUrl:
              `https://control.party.home.mal.co.nz/http-bind`,  //?room=${
                                                                 // state.party.id}`,
          // serviceUrl: 'wss://control.party.home.mal.co.nz/xmpp-websocket',
          hosts: {
            domain: 'meet.jitsi',
            muc: 'muc.meet.jitsi',
          },
          enableWebsocketResume: false,
        });
        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, (e: any) => {
              emitter({connection})
              emitter(END);
            })
        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED, (e: any) => {
              emitter({err: e})
              emitter(END);
            });
        connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, (e: any) => {
              emitter({err: e})
              emitter(END);
            });
        connection.addEventListener(
            JitsiMeetJS.events.connection.WRONG_STATE, (e: any) => {
              emitter({err: e})
              emitter(END);
            });
        connection.connect();
        return () => {
          // connection.disconnect()
        }
      })
    }

interface JoinMessage {
  err?: string
  conference?: any
}

interface JoinEmitters {
  joinedChan: EventChannel<JoinMessage>;
  eventChan: EventChannel<ConferenceEvent>;
  commandChan: EventChannel<ConferenceEvent>;
}

const joinConference = (state: RootState):
    JoinEmitters => {
      const partyId = state.party.party!.id;
      const connection = getConnection();
      const config = {};
      const conference = connection.initJitsiConference(partyId, config)
      const joinedChan: EventChannel<JoinMessage> = eventChannel((emitter) => {
        conference.on(
            JitsiMeetJS.events.conference.CONFERENCE_JOINED,
            (e:any) => {
              console.table(e)
        emitter({conference})
              emitter(END)
            },
        );
        conference.on(
            JitsiMeetJS.events.conference.CONFERENCE_FAILED,
            (e: any) => {
              emitter({err: e.message})
              emitter(END)
            },
        );
              return () => {}
      })
      const eventChan: EventChannel<ConferenceEvent> =
          eventChannel((emitter) => {
            for (const key in JitsiMeetJS.events.conference) {
              const event = JitsiMeetJS.events.conference[key];
              switch (event) {
                case JitsiMeetJS.events.conference.CONFERENCE_JOINED:
                case JitsiMeetJS.events.conference.CONFERENCE_FAILED:
                  continue
              }
              conference.on(event, function(e: any) {
                const args = [];
                for (const arg in arguments) {
                  args.push(arguments[arg])
                }
                emitter({
                  event,
                  message: e,
                  args,
                })
              });
            }
            return () => {}
          })
      const commandChan: EventChannel<ConferenceEvent> =
          eventChannel((emitter) => {
            conference.addCommandListener(`clique`, function(e: any) {
              const args = [];
              for (const arg in arguments) {
                args.push(arguments[arg])
              }
              emitter({
                event: `clique`,
                message: e,
                args,
              })
            });
            return () => {}
          })
      conference.join()
      return {
        commandChan, joinedChan, eventChan
      }
    }

function* connectSaga() {
  try {
    // Attempt to connect to the server first.
    const chan = yield call(connectToJitsi)
    const {err, connection} = yield take(chan)
    if (err) {
      yield put(addNotice(newSimpleNotice('error', err)));
      yield put(connectFailed())
      return
    }
    // Ok, we've sucessfully connected.
    setConnection(connection!)
    yield put(connected())
    // Time to join the conference.
    yield put(join())
  } catch (e) {
    yield put(addNotice(newSimpleNotice('error', e.message)));
    console.warn(e)
  }
}

function* connectedSaga() {
  yield put(addNotice(
      newSimpleNoticeWithDuration('success', 'connected to server', 3000)));
}

function* joinSaga() {
  const state = yield select()
  const {commandChan, joinedChan, eventChan} = yield call(joinConference, state)
  yield spawn(handleEvents, eventChan)
  yield spawn(handleEvents, commandChan)
  const {err, conference} = yield take(joinedChan)
  if (err) {
    yield put(addNotice(newSimpleNotice('error', err)));
    yield put(joinFailed())
    return
  }
  // Set the conference for later retrieval.
  setConference(conference)
  // Add a delay to enable communications to happen before first render.
  // This avoids a bunch of blank boxes etc. which waiting for state from peers.
  yield(delay(1000))
  yield put(joined())
}

function* handleEvents(eventChan: EventChannel<ConferenceEvent>) {
  // Now create a loop on events.
  yield takeEvery(eventChan, eventSaga)
}

function* eventSaga({args, err, event, message}: ConferenceEvent) {
  // Every event comes via here. Some events aren't appropriate for serializing
  // so we syphon them off before doing that.
  switch (event) {
    // Special case for commands
    case `clique`:
      console.warn('known datachannel message', message, args)
      yield put(receivedDataChannelMessage(
          {key: message.value, message: message.attributes}))
      return;
    case JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED:
      if (message.key) {
      } else {
        console.warn('Unknown datachannel message', message, args)
      }
      return;
    case JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED:
      const {conference: {participants}} = yield select()
      const {_id, _properties} = message
      const participant = participants[_id];
      const currentProperties = participant ? participant.properties : {};
      for (const key in _properties) {
        const from = currentProperties[key];
        const to = _properties[key];
        if (from !== to) {
          yield put(participantPropertyDidChange(
              {participantId: _id, name: key, from, to}))
        }
      }
      return;
    case JitsiMeetJS.events.conference.TRACK_ADDED:
      yield call(trackAddedSaga, message);
      return;
    case JitsiMeetJS.events.conference.TRACK_REMOVED:
      yield call(trackRemovedSaga, message);
      return;
    case JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED:
      return;
  }
  // If we don't have an action set up for this event, log it.
  const action = conferenceActions[event];
  if (!action) {
    console.warn('Unhandled conference event', err, event, message, args)
    return
  }
  // @ts-ignore
  yield put(action({message, args, event, err} as ConferenceEvent))
}

function* trackAddedSaga(message: any) {
  // if this is a local track, don't add it.
  if (message.isLocal()) {
    console.log('Local Track?', message)
    return
  }
  const participantId: string = message.getParticipantId();
  const key = addTrack(message)
  if (message.isAudioTrack()) {
    yield put(conferenceActions.setParticipantAudioTrack({participantId, key}));
  }
  else {
    yield put(conferenceActions.setParticipantVideoTrack({participantId, key}));
  }
}

function* trackRemovedSaga(message: any) {
  if (message.isLocal()) {
    return
  }
  if (message.isAudioTrack()) {
    yield put(conferenceActions.removeParticipantAudioTrack(
        message.getParticipantId()))
  } else {
    yield put(conferenceActions.removeParticipantVideoTrack(
        message.getParticipantId()))
  }
}

function* joinedSaga() {
  yield put(addNotice(
      newSimpleNoticeWithDuration('success', 'joined the party', 3000)));
  // Set up the initial party values
  const conference = getConference()
  const participantId: string = conference.myUserId()
  const partyId = (yield select()).party.party.id
  yield put(conferenceActions.setLocalParticipantId(participantId));
  yield put(
      conferenceActions.setParticipantGroupId({participantId, key: partyId}));

  // Now get the local tracks and add them
  const chan = yield call(addLocalTracks)
  const tracks: any[] = yield take(chan)
  for (const i in tracks) {
    conference.addTrack(tracks[i])
    if (tracks[i].isAudioTrack()) {
      yield put(conferenceActions.setParticipantAudioTrack(
          {participantId, key: addTrack(tracks[i])}))
    }
    else {
      yield put(conferenceActions.setParticipantVideoTrack(
          {participantId, key: addTrack(tracks[i])}))
    }
  }
  console.log('tracks', tracks)
}

function* saga() {
  yield takeLatest(connect.toString(), connectSaga);
  yield takeLatest(connected.toString(), connectedSaga);
  yield takeLatest(join.toString(), joinSaga);
  yield takeLatest(joined.toString(), joinedSaga);
}


export default saga;
