import React from 'react'
import {
    RouteComponentProps
} from 'react-router-dom'
import { setId as setWindowId } from '../../window/windowSlice'
import { setId as setLocationId } from '../../location/locationSlice'
import { setId as setPartyId } from '../../party/partySlice'
import { RootState } from '../../../app/store'
import { connect } from 'react-redux'
import { Window } from '../../window/Window'
import { Party } from '../../party/Party'
import { Location } from '../../location/Location'
import WithJitsiConnection from '../../jitsi/components/WithJitsiConnection'
import AudioManager from '../../audio/components/AudioManager'
import VideoLayout from '../../video/components/VideoLayout'
import SettingsBar from '../../settings/components/SettingsBar'
import ShareBanner from '../../settings/components/share/ShareBanner'
import { setFullscreen } from '../../settings/settingsSlice'
import Fullscreen from "react-full-screen";

interface MatchProps {
    partyId: string
    windowId: string
    locationId: string
}
interface ConnectedProps {
    setLocationId: (id: string) => void
    setPartyId: (id: string) => void
    setWindowId: (id: string) => void
    setFullscreen: (enabled: boolean) => void
    window: Window
    location: Location
    party: Party
    fullscreenEnabled: boolean,
}
const Conference = ({ fullscreenEnabled, location, match, window, party, setPartyId, setLocationId, setWindowId, setFullscreen }: RouteComponentProps<MatchProps> & ConnectedProps) => {
    if (window.id === "") {
        // This is a hack. Preferrably look at redux-saga-router where we can perform sagas based on routes.
        setTimeout(() => setWindowId(match.params.windowId), 0)
        return <React.Fragment />
    }
    if (party.id === "") {
        // This is a hack. Preferrably look at redux-saga-router where we can perform sagas based on routes.
        setTimeout(() => setPartyId(match.params.partyId), 0)
        return <React.Fragment />
    }
    if (location.id === "") {
        // This is a hack. Preferrably look at redux-saga-router where we can perform sagas based on routes.
        setTimeout(() => setLocationId(match.params.locationId), 0)
        return <React.Fragment />
    }
    return (
        <div className="bg-gray-900 h-screen w-screen text-white overflow-hidden">
            <WithJitsiConnection>
                <Fullscreen
                    enabled={fullscreenEnabled}
                    onChange={(fullscreenEnabled: boolean) => setFullscreen(fullscreenEnabled)}
                >
                    <AudioManager />
                    <VideoLayout />
                    <SettingsBar />
                    {/* Banner last to go over the other elements */}
                    <ShareBanner />
                </Fullscreen>
            </WithJitsiConnection>
        </div>
    )
}
export default connect(
    ({ window, party, location, settings: { fullscreenEnabled } }: RootState) => ({ party: party.party, window, fullscreenEnabled }),
    {
        setLocationId: (id: string) => setLocationId(id),
        setPartyId: (id: string) => setPartyId(id),
        setWindowId: (id: string) => setWindowId(id),
        setFullscreen: (enabled: boolean) => setFullscreen(enabled),
    }
)(Conference)
