import {Action, configureStore, getDefaultMiddleware, ThunkAction} from '@reduxjs/toolkit';
import {connectRouter} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {combineReducers} from 'redux'
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import audioReducer from '../modules/audio/audioSlice';
import conferenceReducer from '../modules/conference/conferenceSlice';
import dataChannelReducer from '../modules/dataChannel/dataChannelSlice';
import jitsiReducer from '../modules/jitsi/jitsiSlice';
import locationReducer from '../modules/location/locationSlice';
import noticesReducer from '../modules/notices/noticesSlice';
import partyReducer from '../modules/party/partySlice';
import settingsReducer from '../modules/settings/settingsSlice';
import windowReducer from '../modules/window/windowSlice';

import saga from './rootSaga';

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  audio: audioReducer,
  conference: conferenceReducer,
  dataChannel: dataChannelReducer,
  jitsi: jitsiReducer,
  // Location is the shared location information, including all devices and
  // windows.
  location: locationReducer,
  notices: noticesReducer,
  party: partyReducer,
  settings: settingsReducer,
  // Window refers to THIS window, i.e. the window that is currently running
  // this code.
  window: windowReducer,
})
export const history = createBrowserHistory()

const devMode = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware({thunk: false}), sagaMiddleware];

if (devMode) {
  middleware.push(logger);
}
export const store = configureStore({
  reducer: createRootReducer(history),
  devTools: devMode,
  middleware,
});
sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
