import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Message from './messages'

export interface DataChannelState {}

const initialState: DataChannelState = {};

export interface MessagePayload {
  key: string
  message: Message
}

export const slice = createSlice({
  name: 'conference',
  initialState,
  reducers: {
    emitDataChannelMessage:
        (state: DataChannelState, action: PayloadAction<MessagePayload>) => {},
    receivedDataChannelMessage:
        (state: DataChannelState, action: PayloadAction<MessagePayload>) => {},
  },
});

export const {emitDataChannelMessage, receivedDataChannelMessage} =
    slice.actions;

export default slice.reducer;
