import {END, eventChannel, EventChannel} from 'redux-saga'
const JitsiMeetJS: any = (window as any).JitsiMeetJS;

export const addLocalTracks = (): EventChannel<any> => {
  return eventChannel((emitter) => {
    JitsiMeetJS.createLocalTracks({devices: ['video', 'audio']})
        .then((e: any) => {
          emitter(e)
          emitter(END);
        });
    return () => {}
  })
}
