import {createSlice} from '@reduxjs/toolkit';

export interface JitsiState {
  connected: boolean
  connecting: boolean
  joining: boolean
  joined: boolean
}

const initialState: JitsiState = {
  connecting: false,
  connected: false,
  joining: false,
  joined: false,
}

export const slice = createSlice({
  name: 'jitsi',
  initialState,
  reducers: {
    connect: (state) => {
      state.connecting = true
      state.connected = false
    },
    connected: (state) => {
      state.connecting = false
      state.connected = true
    },
    connectFailed: (state) => {
      state.connecting = false
      state.connected = false
    },
    join: (state) => {
      state.joining = true
      state.joined = false
    },
    joined: (state) => {
      state.joined = true
      state.joining = false
    },
    joinFailed: (state) => {
      state.joining = false
      state.joined = false
    }
  },
});

export const {connect, connected, join, joined, connectFailed, joinFailed} =
    slice.actions;

export default slice.reducer;
