import React from 'react'
import { Redirect } from 'react-router'
import {
    RouteComponentProps
} from 'react-router-dom'
import id from '../../modules/id'

type Props = {
    partyId: string
    locationId: string
}
const WindowRedirect = ({ match }: RouteComponentProps<Props>) => {
    return <Redirect to={`/party/${match.params.partyId}/${match.params.locationId}/${id()}`} />
}
export default WindowRedirect
