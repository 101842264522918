import React from 'react'
import { RootState } from '../../../app/store'
import { connect } from 'react-redux'
import { ConferenceState } from '../../conference/conferenceSlice'
import VideoGroup from './VideoGroup'
import { DndProvider } from 'react-dnd'
import id from '../../id'
import Backend from 'react-dnd-html5-backend'
import LonelyBoiBanner from './LonelyBoiBanner';
import { splitParticipantsIntoGroups } from '../../participant/participantSlice'

/**
 * VideoLayout is responsible for the entire layout of all participants, including sub groups.
 */
const VideoLayout = (state: ConferenceState) => {
    const { localGroup, otherGroups } = splitParticipantsIntoGroups(state)
    const { localGroupId } = state
    const minLength = localGroup.length + Object.keys(otherGroups).length
    const classes: string[] = []
    const len = Object.values(localGroup).length
    if (len <= 14) {
        switch (len) {
            case 1:
                if (Object.keys(otherGroups).length > 0) {
                    classes.push(`grid-cols-2`)
                } else {
                    classes.push(`grid-cols-1`)
                }
                break
            case 2:
            case 3:
            case 4:
                if (Object.keys(otherGroups).length > 0) {
                    classes.push(`grid-cols-3`)
                }
                else {
                    classes.push(`grid-cols-2`)
                }
                break
            default:
                classes.push(`grid-cols-3`)
                break
        }
        // classes.push(`grid-cols-${classLookup[Object.keys(participants).length]}`)
    } else {
        classes.push(`grid-cols-5`)
    }
    return (

        <DndProvider backend={Backend}>
            {minLength <= 1 && <LonelyBoiBanner />}
            <VideoGroup
                groupId={localGroupId}
                participants={localGroup}
                className={classes.join(" ")}
                active={true}
                isNext={false}
            />
            {
                Object.keys(otherGroups).map(groupId => <VideoGroup
                    key={groupId}
                    groupId={groupId}
                    className="grid-cols-5"
                    participants={otherGroups[groupId]}
                    active={false}
                    isNext={false}
                />)
            }
            <VideoGroup
                groupId={id()}
                participants={[]}
                active={false}
                isNext={true}
            />
        </DndProvider>
    )
}
export default connect(
    ({ conference }: RootState): ConferenceState => (conference),
)(VideoLayout)
