import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { toggleFullscreen } from '../../settingsSlice'
import { RootState } from '../../../../app/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'

interface MicToggleProps {
    fullscreenEnabled: boolean
    toggleFullscreen: () => void
}
const MicToggle = ({ toggleFullscreen, fullscreenEnabled }: MicToggleProps) => {
    const classes = [
        'w-10', 'h-10', 'rounded-full', 'outline-none', 'focus:outline-none', 'm-4',
        fullscreenEnabled ? "bg-orange-600" : "bg-white",
        fullscreenEnabled ? "text-white" : "text-gray-800",
    ]
    const title = fullscreenEnabled ? "Mic is enabled" : "Mic is disabled"
    return (
        <button
            className={classes.join(" ")}
            onClick={() => toggleFullscreen()}
            title={title}
        >
            <FontAwesomeIcon icon={fullscreenEnabled ? faCompress : faExpand} />
        </button>
    )
}

export default connect(
    ({ settings: { fullscreenEnabled } }: RootState) => ({ fullscreenEnabled }),
    (dispatch: Dispatch<Action>) => ({
        toggleFullscreen: () => dispatch(toggleFullscreen()),
    })
)(MicToggle)
