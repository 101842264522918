import id from '../id'

export enum Type {
  // Simple notifications are basic flash alerts
  Simple,
  // Plugin notices may be interactive, and are owned by the plugin
  Plugin
}

export interface Notice {
  id: string;
  // type represents the top level owner of this notice and how it should be
  // rendered.
  type: Type;
  // subType represents a type within the top level that can be used at will by
  // the notice renderer.
  subType: string;
  // content is a key value store for use by the renderer.
  content: {[key: string]: any};
}

export const newSimpleNotice = (subType: string, message: string): Notice =>
    newSimpleNoticeWithDuration(subType, message, 0)

export const newSimpleNoticeWithDuration =
    (subType: string, message: string, duration: number): Notice =>
        ({id: id(), type: Type.Simple, subType, content: {message, duration}})
