import {PayloadAction} from '@reduxjs/toolkit';
import {put, select, takeEvery} from 'redux-saga/effects'

import {Participant, ParticipantTrack, setParticipantGroupId} from '../conference/conferenceSlice';

import {addTrack, removeTrack} from './audioSlice';

function*
    setParticipantGroupIdSaga(
        {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) {
  const {conference: {localGroupId, localParticipantId, participants}} =
      yield select();
  const participant: Participant = participants[participantId];
  // We don't do our own audio.
  if (participantId === localParticipantId || localParticipantId === '') {
    return
  }
  // This is a no-op, the participant is already a member of the group.
  //   if (participant.groupId === key) {
  //     return
  //   }
  // We're adding this participant to the current group
  if (key === localGroupId) {
    yield put(addTrack(participant.id))
  }
  // If this is true, it means we're remove the participant from the current
  // group.
  else if (participant.groupId === localGroupId) {
    yield put(removeTrack(participant.id))
  }
}
function* removeParticipantAudioTrackSaga({payload}: PayloadAction<string>) {
  yield put(removeTrack(payload))
}

function* saga() {
  yield takeEvery(setParticipantGroupId.toString(), setParticipantGroupIdSaga);
  yield takeEvery(
      removeParticipantAudioTrackSaga.toString(),
      removeParticipantAudioTrackSaga)
}

export default saga;
