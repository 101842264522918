import React from 'react'
import { Action, Dispatch } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toggleShareDisplayed } from '../../settingsSlice'
import { connect } from 'react-redux'
import { RootState } from '../../../../app/store'

interface BannerProps {
    partyId: string
    locationId: string
    isOpen: boolean
    close: () => void
}
interface BannerState {
    partyUrl: string
    locationUrl: string
    copied: boolean
}
class ShareBanner extends React.Component<BannerProps, BannerState> {
    constructor(props: BannerProps) {
        super(props)
        const { locationId, partyId } = props
        const { protocol, host } = window.location
        this.state = {
            partyUrl: `${protocol}//${host}/party/${partyId}`,
            locationUrl: `${protocol}//${host}/party/${partyId}/${locationId}`,
            copied: false,
        };
    }
    render() {
        if (!this.props.isOpen) {
            return <React.Fragment />
        }
        const copy = () => {
            this.setState({ copied: true })
            setTimeout(() => { this.setState({ copied: false }) }, 3000)
        }
        const close = () => {
            this.props.close()
        }
        return (<div className='absolute bottom-0 left-0 w-screen text-center pt-3 pb-3 text-3xl text-white bg-yellow-500' >
            Invite people to your party with this link.
            <button onClick={close} className="text-lg align-middle ml-4">
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <br />
            <span className='bg-yellow-600 p-2 text-xl rounded-lg'>
                <input value={this.state.partyUrl} className='bg-yellow-600 focus:outline-none ml-4' style={{ width: 480 }} />
                <CopyToClipboard
                    text={this.state.partyUrl}
                    onCopy={copy}>
                    <button className='pl-4 focus:outline-none'><FontAwesomeIcon icon={faCopy} /></button>
                </CopyToClipboard>
                {this.state.copied && ' Copied!'}
            </span>
        </div >)
    }
}
export default connect(
    ({ party: { party: { id } }, location, settings: { shareDisplayed } }: RootState) => ({
        partyId: id,
        locationId: location.id,
        isOpen: shareDisplayed,
    }),
    (dispatch: Dispatch<Action>) => ({ close: () => dispatch(toggleShareDisplayed()) }),
)(ShareBanner)
