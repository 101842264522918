import {PayloadAction} from '@reduxjs/toolkit';
import {put, select, takeEvery} from 'redux-saga/effects'

import {RootState} from '../../app/store';
import {getTrack} from '../jitsi/tracks';
import {newSimpleNotice} from '../notices';
import {addNotice} from '../notices/noticesSlice';

import {toggleMic} from './settingsSlice';

function* toggleMicSaga({payload}: PayloadAction<string>) {
  try {
    const {
      settings: {micEnabled},
      conference: {localParticipantId, participants}
    }: RootState =
        yield select()
    if (localParticipantId === '') {
      return
    }
    const participant = participants[localParticipantId];
    if (participant.audioTrackKey === '') {
      return
    }
    const track: any = getTrack(participant.audioTrackKey)
    if (micEnabled) {
      track.unmute()
    }
    else {
      track.mute()
    }
  } catch (e) {
    yield put(addNotice(newSimpleNotice('error', e.message)));
  }
}


function* saga() {
  yield takeEvery(toggleMic.toString(), toggleMicSaga);
}



export default saga;
