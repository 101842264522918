import React from 'react'
import { connect } from 'react-redux'
import Audio from './Audio'
import { RootState } from '../../../app/store'
import { getTrack } from '../../jitsi/tracks'
import { ConferenceState } from '../../conference/conferenceSlice'
import { splitParticipantsIntoGroups } from '../../participant/participantSlice'

interface State {
    // url: string
    // copied: boolean
}
class AudioManager extends React.Component<ConferenceState, State> {

    constructor(props: ConferenceState) {
        super(props)
        this.state = {
        };
    }

    _trackKeys(state: ConferenceState): string[] {
        const { localGroup } = splitParticipantsIntoGroups(state)
        const trackKeys: string[] = []
        if (state.localParticipantId !== "") {
            localGroup.forEach(participant => {
                if (participant.id === state.localParticipantId) {
                    return
                }
                trackKeys.push(participant.audioTrackKey)
            })
        }
        return trackKeys
    }
    shouldComponentUpdate(nextProps: ConferenceState) {
        const currentTrackKeys = this._trackKeys(this.props);
        const nextTrackKeys = this._trackKeys(nextProps);
        let equal = true
        if (currentTrackKeys.length !== nextTrackKeys.length) {
            equal = false
        } else {
            for (const i in currentTrackKeys) {
                if (currentTrackKeys[i] !== nextTrackKeys[i]) {
                    equal = false
                    break
                }
            }
        }
        return !equal;
    }
    render() {
        const { localGroup } = splitParticipantsIntoGroups(this.props)
        const jitsiTracks: any[] = []
        if (this.props.localParticipantId !== "") {
            localGroup.forEach(participant => {
                if (participant.id === this.props.localParticipantId) {
                    return
                }
                jitsiTracks.push(getTrack(participant.audioTrackKey))
            })
        }
        return (
            <React.Fragment>
                {jitsiTracks.map((jitsiTrack, i) => <Audio key={i} audioTrack={{ jitsiTrack }} />)}
            </React.Fragment>
        )
    }
}
export default connect(({ conference }: RootState) => conference)(AudioManager)
