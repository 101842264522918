import {PayloadAction} from '@reduxjs/toolkit';
import {put, takeEvery, takeLatest} from 'redux-saga/effects'

import id from '../../modules/id'
import {setLocation} from '../location/locationSlice'
import {addNotice, newSimpleNotice, newSimpleNoticeWithDuration} from '../notices';
import {setId} from '../window/windowSlice'

import {Party} from './Party'
import {createdParty, createParty} from './partySlice';

function* createPartySaga() {
  try {
    const party: Party = {
      id: id(),
      key: id(),
      conferenceUrl: 'https://test',
    };
    yield put(setLocation({id: id()}))
    yield put(setId(id()))
    // This goes last so the location and window are set.
    yield put(createdParty(party));
  } catch (e) {
    yield put(addNotice(newSimpleNotice('error', e.message)));
  }
}
function* createdPartySaga(action: PayloadAction<Party, string>) {
  try {
    yield put(addNotice(
        newSimpleNoticeWithDuration('success', 'Created Party', 3000)));
    // Couldn't get this working so moved it to a Redirect :(
    // yield put(push(`/party/${action.payload.id}`));
  } catch (e) {
    yield put(addNotice(newSimpleNotice('error', e.message)));
  }
}


/*
Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
Allows concurrent fetches of user.
*/
function* saga() {
  yield takeEvery(createParty.toString(), createPartySaga);
  yield takeLatest(createdParty.toString(), createdPartySaga);
}

/*
Alternatively you may use takeLatest.

Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
dispatched while a fetch is already pending, that pending fetch is cancelled
and only the latest one will be run.
*/
// function* mySaga() {
//   yield takeLatest('USER_FETCH_REQUESTED', fetchUser);
// }

export default saga;
