import {PayloadAction} from '@reduxjs/toolkit';
import {delay, put, takeEvery} from 'redux-saga/effects'

import {addNotice, removeNotice} from '../notices/noticesSlice';

import {Notice, Type} from './Notice'

function* addNoticeSaga({payload: {id, type, content}}: PayloadAction<Notice>) {
  if (type !== Type.Simple) {
    return
  }
  if (content.duration === 0) {
    return
  }
  yield delay(content.duration)
  yield put(removeNotice(id))
}

function* saga() {
  yield takeEvery(addNotice.toString(), addNoticeSaga);
}


export default saga;
