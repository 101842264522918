import {PayloadAction} from '@reduxjs/toolkit';
import {select, takeEvery} from 'redux-saga/effects'

import {Property, setProperty} from '../conference/conferenceSlice';
import {getConference} from '../jitsi/conference';

function*
    setPropertySaga(
        {payload: {name, participantId, value}}: PayloadAction<Property>) {
  const conference = getConference()
  const state = yield select()
  if (participantId === state.conference.localParticipantId) {
    conference.setLocalParticipantProperty(name, value)
  }
}
function* saga() {
  yield takeEvery(setProperty.toString(), setPropertySaga);
}

export default saga;
