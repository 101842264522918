import React from 'react'
import { connect } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { RootState } from '../../../app/store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

interface Props {
    partyId: string
}
interface State {
    url: string
    copied: boolean
}
class LonelyBoiBanner extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        const { partyId } = props
        const { protocol, host } = window.location
        this.state = {
            url: `${protocol}//${host}/party/${partyId}`,
            copied: false,
        };
    }

    render() {
        const onClick = () => {
            this.setState({ copied: true })
            setTimeout(() => { this.setState({ copied: false }) }, 3000)
        }
        return (<div className="m-auto text-center pt-3 pb-3 text-3xl text-white bg-yellow-500" >
            What a lonely boi. Invite people to your party with this link.<br />
            <span className="bg-yellow-600 p-2 text-xl rounded-lg">
                <input value={this.state.url} className="bg-yellow-600 focus:outline-none ml-4" readOnly style={{ width: 480 }} />
                <CopyToClipboard
                    text={this.state.url}
                    onCopy={onClick}>
                    <button className="pl-4 focus:outline-none"><FontAwesomeIcon icon={faCopy} /></button>
                </CopyToClipboard>
                {this.state.copied && " Copied!"}
            </span>
        </div >)
    }
}
export default connect(({ party: { party: { id } } }: RootState) => ({ partyId: id }))(LonelyBoiBanner)
