import {PayloadAction} from '@reduxjs/toolkit';
import {put, select, takeEvery} from 'redux-saga/effects'

import {RootState} from '../../app/store';
import {participantPropertyDidChange, PropertyChange, setProperty} from '../conference/conferenceSlice';
import {emitDataChannelMessage} from '../dataChannel/dataChannelSlice';
import {Keys} from '../dataChannel/messages';
import {getTrack} from '../jitsi/tracks';

import {ParticipantTrack, setParticipantAudioTrack, setParticipantGroupId} from './conferenceSlice';

function*
    setParticipantGroupIdSaga(
        {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) {
  yield put(setProperty({
    participantId,
    name: 'groupId',
    value: key,
  }))
  const {conference: {localParticipantId}} = yield select()
  if (localParticipantId !== '' && participantId !== localParticipantId) {
    yield put(emitDataChannelMessage({
      key: Keys.ParticipantIsInGroup,
      message: {
        participantId,
        groupId: key,
      }
    }))
  }
}

function*
    participantPropertyDidChangeSaga(
        {payload: {name, to, participantId}}: PayloadAction<PropertyChange>) {
  if (name === 'groupId') {
    yield put(setParticipantGroupId({participantId, key: to}))
  }
}
function*
    setParticipantAudioTrackSaga(
        {payload: {key, participantId}}: PayloadAction<ParticipantTrack>) {
  const {conference: {localParticipantId}, settings: {micEnabled}}: RootState =
      yield select()
  if (localParticipantId === '' || participantId !== localParticipantId) {
    return
  }
  const track: any = getTrack(key)
  if (micEnabled) {
    track.unmute()
  }
  else {
    track.mute()
  }
}


function* saga() {
  yield takeEvery(
      setParticipantAudioTrack.toString(), setParticipantAudioTrackSaga);
  yield takeEvery(setParticipantGroupId.toString(), setParticipantGroupIdSaga);
  yield takeEvery(
      participantPropertyDidChange.toString(),
      participantPropertyDidChangeSaga);
}


export default saga;
