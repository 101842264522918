import React from 'react'
import { getTrack } from '../../jitsi/tracks'
import { Participant } from '../../conference/conferenceSlice'
import { DragPreviewImage, useDrag } from 'react-dnd'
import { Types } from '../Constants'
import Video from './Video'

interface Props {
    participant: Participant
    size: string
    className?: string
}

export const Sizes = {
    S: "S",
    M: "M",
    L: "L",
}
// const sizeClasses = {
//     [Sizes.S]: "max-w-sm",
//     [Sizes.M]: "max-w-md",
//     [Sizes.L]: "max-w-lg",
// }

export default ({ participant, size, className }: Props) => {
    const { id, local, connStatus, videoTrackKey } = participant
    const [{ isDragging }, drag, preview] = useDrag({
        item: {
            type: Types.VIDEO,
            participant,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    })
    const classes = [
        className,
        // sizeClasses[size],
        "relative",
        "text-white",
        "bg-gray-600",
    ]
    const jitsiTrack = getTrack(videoTrackKey)
    const style: { [key: string]: any } = {}
    if (local) {
        style['transform'] = 'scaleX(-1)';
    }
    return (
        <div
            ref={drag}
            className={classes.join(" ")}
            style={{
                'opacity': isDragging ? 0.5 : 1,
            }}>
            <DragPreviewImage connect={preview} src="/apple-touch-icon-152x152.png" />
            <div
                className="absolute bottom-0 left-0 bg-gray-800 text-xs p-2 pt-1 pb-1"
                style={{ zIndex: 90 }}
            >
                {id}{local && ` (You)`}{connStatus !== '' && ` (Connection: ${connStatus})`}
            </div>
            <Video className="w-full" videoTrack={{ jitsiTrack }} style={style} />
        </div>
    )
}
